import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Button,
  Tag,
  Select,
  Checkbox,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { TEMPLATE_INITIAL_STATE } from "../../../utils/data";
import { TemplateProps } from "../../../interfaces/interfaces";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
  title: string;
  modalStatus: {
    open: boolean;
    create: boolean;
    update: boolean;
    multiple: boolean;
    payload?: TemplateProps | null;
  };
  onCancel: () => void;
  onSubmit: (values: TemplateProps) => void;
}

const variablesMultiple = ["CustomerName", "BusinessName"];
const variablesSingle = [
  "CustomerName",
  "BusinessName",
  "InvoiceId",
  "InvoiceDate",
  "DueDate",
  "Balance",
  "CustomField1",
  "CustomField2",
  "CustomField3",
];

const DraggableVariable = ({ name }: { name: string }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "variable",
    item: { name },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Tag.CheckableTag
      ref={drag}
      checked={true}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        textAlign: "center",
        margin: "4px 8px",
      }}
    >
      {name}
    </Tag.CheckableTag>
  );
};

const DroppableTextField = ({
  value,
  setValue,
  multiple,
  subject = false,
}: {
  value: any;
  setValue: (prev: any) => void;
  multiple: boolean;
  subject?: boolean;
}) => {
  const textAreaRef = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: "variable",
    drop: (item: any) => {
      setValue((prev: any) => `${prev} {{${item.name}}}`);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Regex to find variable placeholders like {{CustomerName}}
  const variableRegex = /{{(.*?)}}/g;

  // Function to handle rendering the content with uneditable variables
  const renderTextContent = (text: string) => {
    const parts = text.split(variableRegex);
    return parts.map((part: string, index: number) => {
      const formattedPart = part.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
      if (index % 2 === 1) {
        // This is a variable, make it uneditable
        let variableValues = multiple ? variablesMultiple : variablesSingle;
        if (variableValues.includes(part)) {
          return (
            <Tag key={index} color="blue" style={{ marginRight: 4 }}>
              {`{{${part}}}`}
            </Tag>
          );
        } else {
          return <span key={index}>{formattedPart}</span>;
        }
      }
      // Regular text
      return <span key={index}>{formattedPart}</span>;
    });
  };

  return (
    <div ref={drop} style={{ marginTop: "16px" }}>
      <Input.TextArea
        ref={textAreaRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        rows={subject ? 1 : 4}
        autoSize={{ minRows: subject ? 1 : 2, maxRows: subject ? 1 : 10 }}
        style={{
          borderColor: isOver ? "green" : "grey",
          whiteSpace: "pre-wrap", // To maintain formatting
        }}
        placeholder="Drag and drop variables here"
      />
      {value.length > 0 && (
        <>
          <div style={{ marginTop: 8 }}>Preview</div>
          <div
            style={{
              marginTop: "10px",
              backgroundColor: "#fafafa",
              border: "1px solid #d9d9d9",
              padding: "10px",
              whiteSpace: "pre-wrap", // To ensure formatting like line breaks is maintained
            }}
          >
            {renderTextContent(value)}
          </div>
        </>
      )}
    </div>
  );
};

export const ModalTemplateCreateOrUpdate = ({
  title,
  modalStatus: { open, payload },
  onCancel,
  onSubmit,
}: Props) => {
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [form] = Form.useForm();
  const [values, setValues] = useState<TemplateProps>(TEMPLATE_INITIAL_STATE);
  const [template, setTemplate] = useState("");
  const [subjectWithVariables, setSubjectWithVariables] = useState("");
  const [variableValues, setVariableValues] = useState<string[]>([]);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue(TEMPLATE_INITIAL_STATE);
      setValues(TEMPLATE_INITIAL_STATE);
    }
  }, [values]);

  useEffect(() => {
    if (subjectWithVariables && values.TemplateName && template) {
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  }, [values, template]);

  useEffect(() => {
    if (values.Multiple) {
      setVariableValues(variablesMultiple);
    } else {
      setVariableValues(variablesSingle);
    }
  }, [values.Multiple]);

  useEffect(() => {
    if (payload) {
      setValues(payload);
      setTemplate(payload.TemplateContent);
      setSubjectWithVariables(payload.Subject);
    } else {
      setValues(TEMPLATE_INITIAL_STATE);
      setTemplate("");
      setSubjectWithVariables("");
    }
  }, [payload]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        title={title}
        open={open}
        width={800}
        onOk={onCancel}
        onCancel={onCancel}
        footer={null}
        centered
      >
        <Form
          layout="vertical"
          initialValues={values}
          form={form}
          onFinish={() => {
            const newValues = {
              Subject: subjectWithVariables || "",
              TemplateContent: template || "",
              TemplateName: values.TemplateName || null,
              Default: values.Default || false,
              Multiple: values.Multiple || false,
              Initial: values.Initial || false,
              template_15: values.template_15 || false,
              template_30: values.template_30 || false,
              template_45: values.template_45 || false,
              template_60: values.template_60 || false,
              template_75: values.template_75 || false,
              template_90: values.template_90 || false,
              includePaymentLink: values.includePaymentLink || false,
              _id: values._id || null,
            };
            onSubmit(newValues as TemplateProps);
            form.setFieldsValue(TEMPLATE_INITIAL_STATE);
            setValues(TEMPLATE_INITIAL_STATE);
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="1. Template Name"
                name="TemplateName"
                rules={[
                  { required: true, message: "Template name is required" },
                ]}
              >
                <Input
                  placeholder="Name"
                  value={values?.TemplateName}
                  onChange={(event) =>
                    setValues((prev) => {
                      return {
                        ...prev,
                        TemplateName: event.target.value,
                      };
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="2. Template Type">
                <Select
                  style={{ width: "100%" }}
                  value={
                    values.Multiple
                      ? "Multiple"
                      : values.template_15
                        ? "15+"
                        : values.template_30
                          ? "30+"
                          : values.template_45
                            ? "45+"
                            : values.template_60
                              ? "60+"
                              : values.template_75
                                ? "75+"
                                : values.template_90
                                  ? "90+"
                                  : "Default"
                  }
                  onChange={(value) =>
                    setValues((prev) => ({
                      ...prev,
                      Multiple: value === "Multiple",
                      Initial: value === "Initial",
                      template_15: value === "15+",
                      template_30: value === "30+",
                      template_45: value === "45+",
                      template_60: value === "60+",
                      template_75: value === "75+",
                      template_90: value === "90+",
                    }))
                  }
                  options={[
                    {
                      label: "Default",
                      value: "Initial",
                    },
                    {
                      label: "15+",
                      value: "15+",
                    },
                    {
                      label: "30+",
                      value: "30+",
                    },
                    {
                      label: "45+",
                      value: "45+",
                    },
                    {
                      label: "60+",
                      value: "60+",
                    },
                    {
                      label: "75+",
                      value: "75+",
                    },
                    {
                      label: "90+",
                      value: "90+",
                    },
                    {
                      label: "Multiple",
                      value: "Multiple",
                    },
                  ]}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="3. Subject"
                rules={[{ required: true, message: "Subject is required" }]}
              >
                <DroppableTextField
                  value={subjectWithVariables}
                  setValue={setSubjectWithVariables}
                  multiple={values.Multiple}
                  subject
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="4. Check Variables supported: ">
                {variableValues.map((variable) => (
                  <DraggableVariable key={variable} name={variable} />
                ))}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="5. Create Draft of the Content">
                <Form.Item label="Compose Your Message">
                  <DroppableTextField
                    value={template}
                    setValue={setTemplate}
                    multiple={values.Multiple}
                  />
                </Form.Item>
              </Form.Item>
            </Col>

            {
              // Include Payment Link Checkbox
              !values.Multiple && (
                <Col span={24}>
                  <Form.Item name="includePaymentLink" valuePropName="checked">
                    <Checkbox
                      checked={values.includePaymentLink}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          includePaymentLink: e.target.checked,
                        }))
                      }
                    >
                      Include Payment Link
                    </Checkbox>
                  </Form.Item>
                </Col>
              )
            }

            <Col span={24}>
              <Form.Item style={{ textAlign: "right" }}>
                <Button type="default" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 8 }}
                  disabled={!isReadyToSubmit}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </DndProvider>
  );
};
