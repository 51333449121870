import React from "react";
import { Card, List, Avatar, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { InvoiceProps } from "../../interfaces/interfaces";

const { Text } = Typography;

interface InvoiceReminderHistoryProps {
  reminders: InvoiceProps["CollectionDetails"]["RemindersHistory"];
}

export const InvoiceReminderHistory: React.FC<InvoiceReminderHistoryProps> = ({
  reminders,
}) => {
  return (
    <Card title="Last Reminders" className="mb-24" bordered={false}>
      <List
        itemLayout="vertical"
        pagination={{
          pageSize: 5, // Number of reminders per page
          pageSizeOptions: ["5", "10", "15"], // Page size options
        }}
        dataSource={reminders}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              avatar={
                <Avatar
                  size="large"
                  icon={<MailOutlined />}
                  style={{ backgroundColor: "#1890ff" }}
                />
              }
              title={
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Text strong>Subject: {item.subject || "No Subject"}</Text>
                    <br />
                    <Text type="secondary">
                      Sent by: {item.user ? item.user : "System"}
                    </Text>
                    <br />
                    <Text type="secondary">To: {item.ccTo.join(", ")}</Text>
                  </div>
                  <Text type="secondary">
                    {item.date
                      ? dayjs(item.date).format("MM/DD/YYYY HH:mm")
                      : ""}
                  </Text>
                  {/* <br /> */}
                </div>
              }
              description={
                <>
                  <Text>{item.message}</Text>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
