import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
  Select,
  Tooltip,
  message,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import Navbar from "../navigation/Navbar";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Logo, Loading } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import DataContext from "../../context/DataContext";
import AuthContext from "../../context/AuthContext";
import { useDemoModal } from "../../hooks";
import { ModalPreferences } from "../../components/admin";
import { MenuProps } from "antd/lib";
import { getDaysDiffNewAccount } from "../../utils/func/utils";
import { AlertComponent } from "../../components/ui/AlertComponent";

interface Props {
  children: ReactNode;
}
export const ProtectedLayout = ({ children }: Props) => {
  const { DemoModal } = useDemoModal();
  const location = useLocation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, [location.pathname]);
  const { qbSuccess, qbError, alertData } = useContext(QuickbooksContext);
  const { dataError, dataSuccess, dataWarning, handleWarning } =
    useContext(DataContext);
  const {
    userInfo,
    isDemoEnv,
    clientInfo,
    clientList,
    clientIdSelected,
    profileInfo,
    role,
    onLogout,
    handleChangeClientSelected,
  } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState<number>(200);
  const [initials, setInitials] = useState("");
  const [preferencesOpen, setPreferencesOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [messageOpen, setMessageOpen] = useState(false);
  const [openAlertNewUser, setOpenAlertNewUser] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const hasSuccessed = useRef(false);
  const hasFailed = useRef(false);

  message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
  });

  useEffect(() => {
    if (
      !dataError.status &&
      !dataSuccess.status &&
      !dataWarning.status &&
      !qbError.status &&
      !qbSuccess.status
    ) {
      messageApi.destroy();
    }
  }, [dataError, dataSuccess, dataWarning, qbError, qbSuccess]);

  useEffect(() => {
    if (profileInfo?.newAccount?.isNewAccount) {
      setOpenAlertNewUser(true);
      const difference = getDaysDiffNewAccount(profileInfo.newAccount.date);
      difference && setDaysLeft(difference);
    } else {
      setOpenAlertNewUser(false);
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.name) {
        const name = userInfo.name.split(" ");
        setInitials(
          `${name[0] ? name[0].split("")[0] : ""}${
            name[1] ? name[1].split("")[0] : ""
          }`
        );
      } else {
        setInitials("CU");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (qbSuccess.status) {
      success(qbSuccess.message);
    }
  }, [qbSuccess.status]);

  useEffect(() => {
    if (qbError.status) {
      error(qbError.message);
    }
  }, [qbError]);

  useEffect(() => {
    if (dataSuccess.status) {
      success(dataSuccess.message);
    }
  }, [dataSuccess]);

  useEffect(() => {
    if (dataError.status) {
      error(dataError.message);
    }
  }, [dataError]);

  useEffect(() => {
    if (dataWarning.status)
      warning(dataWarning.message, dataWarning.actionLabel, dataWarning.action);
  }, [dataWarning]);

  const success = (message: string) => {
    if (!messageOpen) {
      messageApi.success({
        type: "success",
        content: message,
        onClose: () => setMessageOpen(false),
      });
      hasSuccessed.current = true;
      setMessageOpen(false);
    }
  };

  const error = (message: string) => {
    if (!messageOpen) {
      messageApi.error({
        type: "error",
        content: message,
        onClose: () => setMessageOpen(false),
      });
      hasFailed.current = true;
      setMessageOpen(false);
    }
  };
  const warning = (
    message: string,
    actionLabel?: string,
    action?: () => void
  ) => {
    if (dataWarning.status) {
      messageApi.warning({
        type: "warning",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>{message}</span>
            <Button
              type="primary"
              onClick={() => {
                action && action();
                handleWarning(false, "", "", () => {});
                messageApi.destroy();
              }}
              style={{ width: "150px", margin: "20px" }}
            >
              {actionLabel || "Ok"}
            </Button>
          </div>
        ),
        duration: 10,
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
      });
    } else {
      messageApi.destroy();
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Preferences",
      key: "preferences",
      onClick: () => setPreferencesOpen(true),
    },
    {
      label: "Your company",
      key: "company",
      onClick: () => navigate(`/company/${clientInfo?._id.toString()}`),
    },
    {
      label: "Change Password",
      key: "change-password",
      onClick: () => navigate(`/account-auth/${clientInfo?._id.toString()}`),
    },
    {
      label: "Help",
      key: "help",
      onClick: () => {
        navigate("/help");
      },
    },
    {
      label: "QuickBooks",
      key: "quickbooks",
      onClick: () => navigate(`/quickbooks/${clientInfo?._id.toString()}`),
      style: role === "READ" || role === "DEMO" ? { display: "none" } : {},
    },
    {
      label: "Logout",
      key: "logout",
      onClick: () => onLogout(() => navigate("/login")),
    },
    {
      label: "Version 1.0.6",
      key: "version",
      disabled: true,
    },
  ];

  return (
    <>
      {isDemoEnv && (
        <Alert
          message={
            <>
              This is a demo version of CollectPRO. Please contact the
              administrator to get the full version. Visit{" "}
              <a href="https://collectpro.app" target="_blank">
                collectpro.app
              </a>{" "}
              for more Info
            </>
          }
          banner
          type="warning"
          action={
            <Button
              size="middle"
              type="primary"
              onClick={() => window.open("https://collectpro.app/form")}
            >
              Get a Quote
            </Button>
          }
        />
      )}
      {openAlertNewUser && (
        <Alert
          message={
            <>
              Thank you for signing up! You have {daysLeft} days left in your
              free trial. Visit{" "}
              <a href="https://collectpro.app" target="_blank">
                collectpro.app
              </a>{" "}
              for more Info
            </>
          }
          banner
          type="warning"
          closable
          action={
            <Button
              size="middle"
              type="primary"
              onClick={() => window.open("https://collectpro.app/form")}
            >
              Get a Quote
            </Button>
          }
        />
      )}
      <Layout className="protected-layout">
        {contextHolder}
        <Sider
          breakpoint="sm"
          trigger={null}
          collapsed={collapsed}
          collapsible
          width={siderWidth}
          theme="light"
          onBreakpoint={(broken) => {
            if (broken) {
              setCollapsed(true);
              setSiderWidth(200);
            }
          }}
        >
          <div className="protected-layout__logo">
            <Logo mobile={collapsed} />
          </div>
          <Navbar />
        </Sider>
        <Layout>
          <Header className="protected-layout__header">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Select
              disabled={!(clientList && clientList?.length > 1)}
              style={{ width: xs ? 100 : 210 }}
              value={clientIdSelected}
              onChange={(value) => handleChangeClientSelected(value)}
              options={
                clientList
                  ? clientList.map((item, index) => {
                      return {
                        label: item.name,
                        value: item._id,
                      };
                    })
                  : []
              }
            ></Select>
            <Tooltip title={userInfo?.name} placement="left">
              <Dropdown menu={{ items }}>
                <Avatar
                  style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    backgroundColor: "#17549a",
                  }}
                  gap={2}
                >
                  {initials}
                </Avatar>
              </Dropdown>
            </Tooltip>
          </Header>
          {/* {isVisible ? ( */}
          <Content className="protected-layout__dashboard">
            <Content className="protected-layout__dashboard__content">
              {children}
            </Content>
          </Content>
          {/* ) : (
            <Content className="protected-layout__dashboard">
              <Content className="protected-layout__dashboard__content"></Content>
            </Content>
          )} */}
        </Layout>
        {DemoModal()}
        <ModalPreferences
          open={preferencesOpen}
          onCancel={() => setPreferencesOpen(false)}
        />
      </Layout>
      {alertData.message && <AlertComponent {...alertData} />}
    </>
  );
};
