import {
  Button,
  Card,
  Collapse,
  CollapseProps,
  Divider,
  Empty,
  Popconfirm,
  Row,
  TableColumnsType,
  Tooltip,
  Tour,
  TourProps,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardComponent, CardTitle, ColumnComponent } from "../../components/ui";
import Table from "antd/es/table";
import QuickbooksContext from "../../context/QuickbooksContext";
import dayjs, { Dayjs } from "dayjs";
import { InvoiceDrawer } from "../../components/collections/InvoiceDrawer";
import DataContext from "../../context/DataContext";
import { useParams, useSearchParams } from "react-router-dom";
import { MultipleSelect, SearchInput } from "../../components/functional";
import html2canvas from "html2canvas";
import { exportDetailFile } from "../../utils/func/ExportFile";
import {
  DownloadOutlined,
  MailOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import AuthContext from "../../context/AuthContext";
import { USDollar } from "../../utils/func/currency";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";
import { ModalCollectionsBulkUpdate } from "../../components/collections";
import { useDemoModal } from "../../hooks";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { InvoiceProps } from "../../interfaces/interfaces";
import QueueAnim from "rc-queue-anim";

const { Text, Link } = Typography;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  filters?: Record<string, FilterValue>;
}

export const DetailClient = () => {
  const { idClient } = useParams();
  const { DemoModal, openModalDemo } = useDemoModal();
  const [items, setItems] = useState<CollapseProps["items"]>([]);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | string[]>([]);
  const [asOfDate, setAsOfDate] = useState(dayjs());
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [selectedCustomerToEmail, setSelectedCustomerToEmail] = useState("");
  const [selectedCurrent, setSelectedCurrent] = useState<React.Key[]>([]);
  const [selected_1_30, setSelected_1_30] = useState<React.Key[]>([]);
  const [selected_31_60, setSelected_31_60] = useState<React.Key[]>([]);
  const [selected_61_90, setSelected_61_90] = useState<React.Key[]>([]);
  const [selected_90, setSelected_90] = useState<React.Key[]>([]);
  const {
    detailData,
    isInvoiceDrawerOpen,
    invoiceListSelected,
    invoiceSelected,
    indexInvoiceSelected,
    qbLoading,
    getData,
    handleSelectInvoiceList,
    handleCancelSelectInvoiceList,
    updateSelectedInvoice,
    handleSelectInvoice,
    handleGetQbData,
    handleChangeFilters,
    handleBulkEdit,
    onChangeSearchQuery,
    onDemandReminderSingle,
  } = useContext(QuickbooksContext);
  const [hideColumnsClass, setHideColumnsClass] = useState(false);
  const [columnsFiltered, setColumnsFiltered] =
    useState<ColumnsType<InvoiceProps>>();
  const { clientInfo, isDemoEnv, authLoading, profileInfo, role } =
    useContext(AuthContext);
  const [customerFiltered, setCustomerFiltered] = useState([]);
  const [customerSelected, setCustomerSelected] = useState([]);
  const [statusSelected, setStatusSelected] = useState([]);
  const [defaultSortKey, setDefaultSortKey] = useState({
    name: "2",
    order: "ascend",
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      onChange: (_, pageSize) => {
        setTableParams((prev) => {
          return {
            ...prev,
            pagination: {
              ...prev.pagination,
              pageSize: pageSize,
            },
          };
        });
      },
    },
  });
  const {
    getData: getListData,
    statusList,
    customerOptions,
    statusOptions,
    isTourOpen,
    handleSuccess,
    handleTour,
  } = useContext(DataContext);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const screenRef = useRef(null);

  useEffect(() => {
    onChangeSearchQuery("");
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour-detail");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour-detail", "true");
      }
    }
  }, []);

  useEffect(() => {
    if (customerFiltered.length === 1) {
      setSelectedCustomerToEmail(customerFiltered[0]);
    } else if (customerSelected.length === 1) {
      setSelectedCustomerToEmail(customerSelected[0]);
    } else {
      setSelectedCustomerToEmail("");
    }
  }, [customerFiltered, customerSelected]);

  useEffect(() => {
    setColumnsFiltered(
      columns.map((item) => {
        if (
          item.key === "8" ||
          item.key === "9" ||
          item.key === "10" ||
          item.key === "11" ||
          item.key === "12"
        ) {
          item.hidden = hideColumnsClass;
        }
        return {
          ...item,
        };
      })
    );
  }, [hideColumnsClass]);

  useEffect(() => {
    if (profileInfo?.preferences?.collections?.detail?.columns) {
      setColumnsFiltered(
        columns.map((item) => {
          if (
            profileInfo?.preferences?.collections?.detail?.columns?.includes(
              (item as ColumnType<InvoiceProps>).dataIndex as any
            )
          ) {
            item.hidden = false;
          } else {
            item.hidden = true;
          }
          return {
            ...item,
          };
        })
      );
    }
  }, [profileInfo?.preferences?.collections?.detail?.columns]);

  useEffect(() => {
    const defaultKey = profileInfo?.preferences?.collections?.detail
      ?.defaultSortColumnKey || { name: "2", order: "ascend" };
    setDefaultSortKey(defaultKey);
    setColumnsFiltered(
      columns.map((item) => {
        if (item.key === defaultKey.name) {
          item.defaultSortOrder =
            (defaultKey.order as "ascend" | "descend") || "ascend";
        }
        return {
          ...item,
        };
      })
    );
  }, [profileInfo]);

  useEffect(() => {
    if (
      selectedCurrent.length > 0 ||
      selected_1_30.length > 0 ||
      selected_31_60.length > 0 ||
      selected_61_90.length > 0 ||
      selected_90.length > 0
    ) {
      setHasSelected(true);
    } else {
      setHasSelected(false);
    }
  }, [
    selectedCurrent,
    selected_1_30,
    selected_31_60,
    selected_61_90,
    selected_90,
  ]);

  useEffect(() => {
    if (idClient) {
      getListData("customer", idClient);
      getListData("status", idClient);
    }
  }, [idClient]);

  useEffect(() => {
    if (searchParams.get("filter") && searchParams.get("filter") !== null) {
      const filter = searchParams.get("filter") || "";
      setActiveTab(filter);
    }
  }, [searchParams, items]);

  useEffect(() => {
    if (idClient)
      if (
        searchParams.get("add_filter") &&
        searchParams.get("add_filter") !== null
      ) {
        const idCustomer = searchParams.get("add_filter") || "";
        if (idCustomer !== "") {
          handleChangeFilters("detail", idClient, "customer", [idCustomer]);
          setCustomerFiltered([idCustomer] as any);
        }
      } else if (
        searchParams.get("status") &&
        searchParams.get("status") !== null
      ) {
        const idStatus = searchParams.get("status") || "";
        if (idStatus !== "") {
          handleChangeFilters("detail", idClient, "status", [idStatus]);
          setStatusSelected([idStatus] as any);
        }
      } else {
        getData({ endpoint: "detail", idClient });
      }
  }, []);

  const rowSelectionCurrent = {
    selectedCurrent,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedCurrent(newSelectedRowKeys);
    },
  };

  const rowSelection1_30 = {
    selected_1_30,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelected_1_30(newSelectedRowKeys);
    },
  };

  const rowSelection_31_60 = {
    selected_31_60,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelected_31_60(newSelectedRowKeys);
    },
  };

  const rowSelection_61_90 = {
    selected_61_90,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelected_61_90(newSelectedRowKeys);
    },
  };

  const rowSelection_90 = {
    selected_90,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelected_90(newSelectedRowKeys);
    },
  };

  useEffect(() => {
    setTableParams({
      pagination: {
        pageSize: 100,
        onChange: (_, pageSize) => {
          setTableParams((prev) => {
            return {
              ...prev,
              pagination: {
                ...prev.pagination,
                pageSize: pageSize,
              },
            };
          });
        },
      },
    });
  }, []);

  useEffect(() => {
    const newItems = [];
    if (detailData) {
      detailData.current.length > 0 &&
        newItems.push({
          key: "Current",
          label: (
            <>
              <strong>Current</strong>
              <Divider type="vertical" />
              <Link>See All Invoices {" >"}</Link>{" "}
            </>
          ),
          children: (
            <Card
              bordered
              hoverable
              className="card overflow_x detail-client__collapse__card"
              ref={ref8}
            >
              {detailData.current.length > 0 ? (
                <Table
                  className="detail-client__collapse__card__table"
                  rowSelection={rowSelectionCurrent}
                  pagination={tableParams.pagination}
                  bordered
                  columns={columnsFiltered}
                  dataSource={detailData.current}
                  size="small"
                  rowKey="DocNumber"
                  tableLayout="auto"
                />
              ) : (
                <Empty />
              )}
            </Card>
          ),
          showArrow: false,
          extra: (
            <>
              <strong>Total Current: </strong>
              {USDollar.format(detailData.totalCurrent)}
            </>
          ),
        });
      detailData["1-30"].length > 0 &&
        newItems.push({
          key: "1-30",
          label: (
            <>
              <strong>1-30</strong>
              <Divider type="vertical" />
              <Link>See All Invoices {" >"}</Link>{" "}
            </>
          ),
          children: (
            <Card
              bordered
              hoverable
              className="card overflow_x detail-client__collapse__card"
            >
              {detailData["1-30"].length > 0 ? (
                <Table
                  className="detail-client__collapse__card__table"
                  rowSelection={rowSelection1_30}
                  pagination={tableParams.pagination}
                  bordered
                  columns={columnsFiltered}
                  dataSource={detailData["1-30"]}
                  size="small"
                  rowKey="DocNumber"
                  tableLayout="auto"
                />
              ) : (
                <Empty />
              )}
            </Card>
          ),
          showArrow: false,
          extra: (
            <>
              <strong>Total {"1-30"}: </strong>{" "}
              {USDollar.format(detailData.total_1_30)}
            </>
          ),
        });
      detailData["31-60"].length > 0 &&
        newItems.push({
          key: "31-60",
          label: (
            <>
              <strong>31-60</strong>
              <Divider type="vertical" />
              <Link>See All Invoices {" >"}</Link>{" "}
            </>
          ),
          children: (
            <Card
              bordered
              hoverable
              className="card overflow_x detail-client__collapse__card"
            >
              {detailData["31-60"].length > 0 ? (
                <Table
                  className="detail-client__collapse__card__table"
                  rowSelection={rowSelection_31_60}
                  pagination={tableParams.pagination}
                  bordered
                  columns={columnsFiltered}
                  dataSource={detailData["31-60"]}
                  size="small"
                  rowKey="DocNumber"
                  tableLayout="auto"
                />
              ) : (
                <Empty />
              )}
            </Card>
          ),
          showArrow: false,
          extra: (
            <>
              <strong>Total {"31-60"}: </strong>
              {USDollar.format(detailData.total_31_60)}
            </>
          ),
        });
      detailData["61-90"].length > 0 &&
        newItems.push({
          key: "61-90",
          label: (
            <>
              <strong>61-90</strong>
              <Divider type="vertical" />
              <Link>See All Invoices {" >"}</Link>
            </>
          ),
          children: (
            <Card
              bordered
              hoverable
              className="card overflow_x detail-client__collapse__card"
            >
              {detailData["61-90"].length > 0 ? (
                <Table
                  className="detail-client__collapse__card__table"
                  rowSelection={rowSelection_61_90}
                  pagination={tableParams.pagination}
                  bordered
                  columns={columnsFiltered}
                  dataSource={detailData["61-90"]}
                  size="small"
                  rowKey="DocNumber"
                  tableLayout="auto"
                />
              ) : (
                <Empty />
              )}
            </Card>
          ),
          showArrow: false,
          extra: (
            <>
              <strong>Total {"61-90"}: </strong>
              {USDollar.format(detailData.total_61_90)}
            </>
          ),
        });
      detailData[">90"].length > 0 &&
        newItems.push({
          key: ">90",
          label: (
            <>
              <strong>{">90"}</strong>
              <Divider type="vertical" />
              <Link>See All Invoices {" >"}</Link>{" "}
            </>
          ),
          children: (
            <Card
              bordered
              hoverable
              className="card overflow_x detail-client__collapse__card"
            >
              {detailData[">90"].length > 0 ? (
                <Table
                  rowSelection={rowSelection_90}
                  className="detail-client__collapse__card__table"
                  pagination={tableParams.pagination}
                  bordered
                  columns={columnsFiltered}
                  dataSource={detailData[">90"]}
                  size="small"
                  rowKey="DocNumber"
                  tableLayout="auto"
                  loading={qbLoading || authLoading}
                />
              ) : (
                <Empty />
              )}
            </Card>
          ),
          showArrow: false,
          extra: (
            <>
              <strong>Total {">90"}: </strong>
              {USDollar.format(detailData.total_90)}
            </>
          ),
        });
      detailData.total &&
        newItems.push({
          key: "total",
          label: <strong>TOTAL</strong>,
          showArrow: false,
          extra: (
            <>
              <strong>TOTAL: </strong>
              {USDollar.format(detailData.total)}
            </>
          ),
        });
      setItems(newItems);
    }
  }, [detailData, columnsFiltered, profileInfo]);

  const getLastUpdate = () => {
    if (detailData.lastUpdate && detailData.lastUpdate.CollectionsDate)
      return (
        <Text style={{ color: "#FFF" }}>
          Last update on:{" "}
          {dayjs
            .utc(detailData.lastUpdate.CollectionsDate)
            .format("MM/DD/YYYY HH:mm")}
        </Text>
      );
  };

  const editInvoices = (newStatus: {
    Status: string | null;
    LastContact: Dayjs | null;
    NewNote: string | null;
  }) => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      let selectedRowsToEdit = [
        ...selectedCurrent,
        ...selected_1_30,
        ...selected_31_60,
        ...selected_61_90,
        ...selected_90,
      ];
      clientInfo &&
        handleBulkEdit({
          endpoint: "invoice",
          idClient: clientInfo?._id,
          items: selectedRowsToEdit as string[],
          newStatus,
          next: () => {
            setSelectedCurrent([]);
            setSelected_1_30([]);
            setSelected_31_60([]);
            setSelected_61_90([]);
            setSelected_90([]);
            if (idClient)
              handleChangeFilters(
                "detail",
                idClient,
                "customer",
                customerFiltered
              );
          },
        });
    }
    setIsEditModalOpen(false);
  };

  const sendIndividualReminders = () => {
    if (isDemoEnv) {
      openModalDemo();
    } else {
      if (clientInfo) {
        let selectedRowsToEdit = [
          ...selectedCurrent,
          ...selected_1_30,
          ...selected_31_60,
          ...selected_61_90,
          ...selected_90,
        ];
        selectedRowsToEdit.length > 0 &&
          onDemandReminderSingle({
            idClient: clientInfo._id,
            docNumberList: selectedRowsToEdit as string[],
            next: () => {
              clientInfo &&
                handleBulkEdit({
                  endpoint: "invoice",
                  idClient: clientInfo?._id,
                  items: selectedRowsToEdit as string[],
                  newStatus: {
                    Status: null,
                    LastContact: dayjs(),
                    NewNote: "Invoices Notification sent",
                  },
                  next: () => {
                    setSelectedCurrent([]);
                    setSelected_1_30([]);
                    setSelected_31_60([]);
                    setSelected_61_90([]);
                    setSelected_90([]);
                    setHasSelected(false);
                    if (idClient)
                      handleChangeFilters(
                        "detail",
                        idClient,
                        "customer",
                        customerFiltered
                      );
                  },
                });
            },
          });
      }
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Toolbar",
      description:
        "This is the toolbar, where you can find all the actions, filters, and export options.",
      target: () => ref1.current,
    },
    {
      title: "As of Date",
      description: "This is the date of the A/R Aging Detail.",
      target: () => ref2.current,
    },
    {
      title: "Edit Single/Multiple Invoice(s)",
      description:
        "Edit the status, email address, phone number of one or multiple invoices at once.",
      target: () => ref3.current,
    },
    {
      title: "Take screenshot",
      description:
        "Take an screenshot of the A/R Aging Detail and copy it to the clipboard.",
      target: () => ref9.current,
    },
    {
      title: "Send Email Reminder",
      description:
        "Send Invoice reminder when one or multiple invoices are selected",
      target: () => ref4.current,
    },
    {
      title: "Export Data",
      description: "Export the A/R Aging Detail data to a CSV file.",
      target: () => ref5.current,
    },
    {
      title: "Filter By Status",
      description: "Filter the A/R Aging Detail by status.",
      target: () => ref6.current,
    },
    {
      title: "Filter By Customer",
      description: "Filter the A/R Aging Detail by customer.",
      target: () => ref7.current,
    },
    {
      title: "A/R Detail",
      description: "Here you can see the A/R Detail By Aging.",
      target: () => ref8.current,
    },
  ];

  const handleScreenshot = async () => {
    if (screenRef.current) {
      setHideColumnsClass(true);
      await new Promise((resolve, reject) => {
        resolve(
          setTimeout(() => {
            html2canvas(screenRef.current as any).then((canvas) => {
              canvas.toBlob((blob) => {
                const item = new ClipboardItem({ "image/png": blob } as any);
                navigator.clipboard.write([item]);
              });
            });
            handleSuccess(true, "Screenshot copied to clipboard");
          }, 2000)
        );
      });
      setHideColumnsClass(false);
    }
  };

  const columns: TableColumnsType<InvoiceProps> = [
    {
      title: "Created at",
      key: "1",
      dataIndex: "MetaData",
      render: (_, { MetaData }) => (
        <>{MetaData ? dayjs(MetaData.CreateTime).format("MM/DD/YYYY") : ""}</>
      ),
      className: "table-row",
      sorter: {
        multiple: 1,
        compare: (a, b) =>
          new Date(a.MetaData.CreateTime).getTime() -
          new Date(b.MetaData.CreateTime).getTime(),
      },
    },
    {
      title: "Name",
      key: "2",
      dataIndex: "CustomerRef",
      render: (_, { CustomerRef }) => (
        <Button type="link">{CustomerRef ? CustomerRef.name : ""}</Button>
      ),
      onCell: (record, index) => {
        return {
          onClick: () => handleSelectInvoiceList("detail", record),
        };
      },
      sorter: {
        compare: (a, b) =>
          (a.CustomerRef?.name || "").localeCompare(b.CustomerRef?.name || ""),
        multiple: 2,
      },
      className: "table-row",
    },
    {
      title: "Num",
      key: "3",
      dataIndex: "DocNumber",
      sorter: {
        compare: (a, b) => (a.DocNumber || "").localeCompare(b.DocNumber || ""),
        multiple: 3,
      },
      render: (_, { DocNumber }) => (
        <Button type="link">{DocNumber || ""}</Button>
      ),
      onCell: (record) => {
        return {
          onClick: () => handleSelectInvoiceList("detail", record),
        };
      },
      className: "table-row",
    },
    {
      title: "Type",
      key: "4",
      dataIndex: "TransactionType",
      sorter: {
        compare: (a, b) =>
          (a.TransactionType || "").localeCompare(b.TransactionType || ""),
        multiple: 4,
      },
      className: "table-row",
    },
    {
      title: "Terms",
      key: "5",
      dataIndex: "SalesTermRef",
      render: (_, { SalesTermRef }) => (
        <>{SalesTermRef ? SalesTermRef.name : ""}</>
      ),
      sorter: {
        compare: (a, b) =>
          (a.SalesTermRef?.name || "").localeCompare(b.SalesTermRef?.name),
        multiple: 5,
      },
      className: "table-row",
    },
    {
      title: "Due Date",
      dataIndex: "DueDate",
      key: "6",
      render: (_, row) => (
        <>
          {row.DueDate
            ? dayjs(row.DueDate).format("MM/DD/YYYY")
            : row.TxnDate
              ? dayjs(row.TxnDate).format("MM/DD/YYYY")
              : ""}
        </>
      ),
      className: "table-row",
      sorter: {
        compare: (a, b) =>
          new Date(a.DueDate).getTime() - new Date(b.DueDate).getTime(),
        multiple: 6,
      },
    },
    {
      title: "Balance",
      key: "7",
      dataIndex: "Balance",
      className: "align-right table-row",
      sorter: {
        compare: (a, b) => a.Balance - b.Balance,
        multiple: 7,
      },
      render: (_, { Balance }) => (
        <Button type="link">{USDollar.format(Balance) || ""}</Button>
      ),
      onCell: (record) => {
        return {
          onClick: () => handleSelectInvoiceList("detail", record),
        };
      },
    },
    {
      title: "Status",
      key: "8",
      dataIndex: "status_field",
      render: (_, { status_field }) => (
        <>
          {status_field && status_field[0].Label ? status_field[0].Label : ""}
        </>
      ),
      className: "table-row",
      sorter: {
        compare: (a, b) =>
          (a &&
            b &&
            a.status_field &&
            b.status_field &&
            a.status_field[0].Label.localeCompare(b.status_field[0].Label)) ||
          0,
        multiple: 8,
      },
    },
    {
      title: "Last Contact",
      key: "9",
      dataIndex: "CollectionDetails",
      responsive: ["md", "lg", "xl", "xxl"],
      render: (_, { CollectionDetails }) => (
        <>
          {CollectionDetails?.LastContact
            ? dayjs(CollectionDetails.LastContact).format("MM/DD/YYYY")
            : ""}
        </>
      ),
      className: `table-row`,
      sorter: {
        compare: (a, b) =>
          new Date(a.CollectionDetails.LastContact as any).getTime() -
          new Date(b.CollectionDetails.LastContact as any).getTime(),
        multiple: 9,
      },
    },
    {
      title: "Last Note",
      key: "11",
      dataIndex: "CollectionDetails",
      responsive: ["md", "lg", "xl", "xxl"],
      width: "100%",
      render: (_, { CollectionDetails }) => (
        <div style={{ width: "15vw" }}>
          {CollectionDetails?.LastNotes &&
          CollectionDetails?.LastNotes.length > 0
            ? CollectionDetails?.LastNotes[0]?.body
            : ""}
        </div>
      ),
      className: `table-row`,
      sorter: {
        compare: (a, b) =>
          (
            (a?.CollectionDetails?.LastNotes &&
              a?.CollectionDetails?.LastNotes[0]?.body) ||
            ""
          ).localeCompare(
            (b?.CollectionDetails?.LastNotes &&
              (b?.CollectionDetails?.LastNotes[0]?.body as any)) ||
              ""
          ),
        multiple: 11,
      },
    },
    {
      title: "By",
      key: "12",
      dataIndex: "CollectionDetails",
      responsive: ["md", "lg", "xl", "xxl"],
      width: "100%",
      render: (_, { CollectionDetails }) => (
        <>
          {CollectionDetails.LastNotes &&
          CollectionDetails?.LastNotes?.length > 0
            ? CollectionDetails.LastNotes[0]?.user?.name
            : ""}
        </>
      ),
      className: `table-row`,
      sorter: {
        compare: (a, b) =>
          (
            (a?.CollectionDetails?.LastNotes &&
              a?.CollectionDetails?.LastNotes[0]?.user?.name) ||
            ""
          ).localeCompare(
            (b?.CollectionDetails?.LastNotes &&
              b?.CollectionDetails?.LastNotes[0]?.user?.name) ||
              ""
          ),
        multiple: 12,
      },
    },
  ];

  return (
    <>
      <Row gutter={[24, 0]}>
        <ColumnComponent>
          <CardTitle
            className="detail-client__card__title"
            title={clientInfo?.name + " - A/R Aging Detail"}
            extra={
              isDemoEnv ? null : (
                <>
                  {getLastUpdate()}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#2CA01C",
                        color: "#FFF",
                        borderColor: "#2CA01C",
                      }}
                      loading={qbLoading}
                      size="small"
                      onClick={() =>
                        idClient &&
                        handleGetQbData(idClient, () =>
                          getData({
                            endpoint: "detail",
                            idClient,
                            filters: {
                              customer:
                                customerSelected.length > 0
                                  ? customerSelected
                                  : null,
                              status:
                                statusSelected.length > 0
                                  ? statusSelected
                                  : null,
                            },
                          })
                        )
                      }
                    >
                      Refresh From QuickBooks
                    </Button>
                  </div>
                </>
              )
            }
          />
        </ColumnComponent>
        <ColumnComponent>
          <div ref={ref1}>
            <CardComponent
              className="detail-client__card"
              title={
                <>
                  A/R Aging Detail as of{" "}
                  <span ref={ref2}>
                    {dayjs(asOfDate).format("MM/DD/YYYY")}
                    {/* <DatePicker
                      className="detail-client__card__title__date-picker"
                      format={"MM/DD/YYYY"}
                      value={asOfDate}
                      style={{ marginLeft: "10px" }}
                      allowClear={false}
                      onChange={(_, date) => {
                        setAsOfDate(dayjs(date as any));
                        if (idClient)
                          getData({
                            endpoint: "detail",
                            idClient,
                            dates: ["", "", date as any],
                            filters: {
                              customer:
                                customerSelected.length > 0
                                  ? customerSelected
                                  : null,
                              status:
                                statusSelected.length > 0
                                  ? statusSelected
                                  : null,
                            },
                          });
                      }}
                    /> */}
                  </span>
                </>
              }
              extra={[
                <SearchInput
                  onSearch={(value: string) => {
                    if (value) {
                      idClient &&
                        getData({
                          endpoint: "detail",
                          idClient,
                          search: value,
                        });
                    } else {
                      idClient && getData({ endpoint: "detail", idClient });
                    }
                  }}
                />,
              ]}
              actions={[
                <div ref={ref3}>
                  <Tooltip title="Edit" placement="left">
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      disabled={!hasSelected}
                      size="large"
                      onClick={() => setIsEditModalOpen(true)}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref9}>
                  <Tooltip title="Take Screenshot" placement="left">
                    <Button
                      disabled={hideColumnsClass}
                      type="link"
                      icon={<PictureOutlined />}
                      size="large"
                      onClick={handleScreenshot}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref4}>
                  <Tooltip title="Send Individual Reminders" placement="left">
                    <Popconfirm
                      title="Send Individual Reminders?"
                      onConfirm={sendIndividualReminders}
                    >
                      <Button
                        type="link"
                        disabled={!hasSelected}
                        loading={qbLoading}
                        icon={<MailOutlined />}
                        size="large"
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>,
                <div ref={ref5}>
                  <Tooltip title="Export">
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={() => exportDetailFile(detailData)}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref6}>
                  <Tooltip title="Filter By Status">
                    <MultipleSelect
                      placeholder="Status"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        width: "80%",
                      }}
                      defaultValue={statusSelected}
                      options={statusOptions}
                      onSelect={(values) => {
                        if (idClient) {
                          setStatusSelected(values as any);
                          searchParams.delete("status");
                          handleChangeFilters(
                            "detail",
                            idClient,
                            "status",
                            values
                          );
                        }
                      }}
                    />
                  </Tooltip>
                </div>,
                <div ref={ref7}>
                  <Tooltip title="Filter By Customer">
                    <MultipleSelect
                      placeholder="Customer"
                      style={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        width: "80%",
                      }}
                      defaultValue={customerFiltered}
                      options={customerOptions}
                      onSelect={(values) => {
                        setCustomerFiltered([]);
                        setCustomerSelected(values as any);
                        searchParams.delete("add_filter");
                        idClient &&
                          handleChangeFilters(
                            "detail",
                            idClient,
                            "customer",
                            values
                          );
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            ></CardComponent>
          </div>
        </ColumnComponent>
        <ColumnComponent>
          <>
            {items && items.length > 0 ? (
              <div ref={screenRef}>
                <Collapse
                  className="detail-client__collapse"
                  bordered={false}
                  items={items}
                  activeKey={activeTab}
                  onChange={(key) => setActiveTab(key)}
                />
              </div>
            ) : (
              <Collapse
                className="detail-client__collapse"
                bordered={false}
                items={[]}
                activeKey={activeTab}
                onChange={(key) => setActiveTab(key)}
              />
            )}
          </>
        </ColumnComponent>
        <ColumnComponent>
          <div style={{ textAlign: "right" }}>
            <Tooltip title="Help">
              <Button
                icon={<QuestionCircleOutlined style={{ color: "#17549a" }} />}
                type="default"
                onClick={() => {
                  handleTour(true);
                }}
              />
            </Tooltip>
          </div>
        </ColumnComponent>
      </Row>
      <InvoiceDrawer
        title={`Collections (${invoiceSelected.TransactionType} ${
          indexInvoiceSelected + 1
        } of ${invoiceListSelected.length})`}
        open={isInvoiceDrawerOpen}
        onClose={handleCancelSelectInvoiceList}
        invoice={invoiceSelected}
        options={statusList}
        idClient={idClient}
        indexInvoiceSelected={indexInvoiceSelected}
        list={invoiceListSelected}
        selectInvoice={(indexSelected) => handleSelectInvoice(indexSelected)}
        onClickNext={
          indexInvoiceSelected < invoiceListSelected.length - 1
            ? () => handleSelectInvoice(indexInvoiceSelected + 1)
            : undefined
        }
        onClickPrevious={
          indexInvoiceSelected !== undefined && indexInvoiceSelected > 0
            ? () => handleSelectInvoice(indexInvoiceSelected - 1)
            : undefined
        }
        onSubmit={(newNote, emailAddress, phoneNumber, newDate) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            if (idClient) {
              updateSelectedInvoice(
                idClient,
                {
                  newNote,
                  emailAddress,
                  phoneNumber,
                  newDate,
                },
                () => {}
              );
            }
          }
        }}
      />
      <ModalCollectionsBulkUpdate
        title="Edit Selected Invoice(s)"
        isModalOpen={isEditModalOpen}
        onSubmit={editInvoices}
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
      />
      <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} />
      <DemoModal />
    </>
  );
};
