import React, { useContext, useEffect, useState } from "react";
import { Modal, Input, Form, Button, Row, Col, Divider, Checkbox } from "antd";
import QuickbooksContext from "../../context/QuickbooksContext";
import PdfViewer from "../pdf/PdfViewer";
import { EmailPreviewProps } from "../../interfaces/interfaces";
import { EMAIL_PREVIEW_INITIAL_STATE } from "../../utils/data";
import { validateEmailsString } from "../../utils/func/utils";

interface EmailPreviewModalProps {
  visible: boolean;
  emailData: EmailPreviewProps;
  onCancel: () => void;
  onSend: (values: any) => void;
}

export const EmailPreviewModal: React.FC<EmailPreviewModalProps> = ({
  visible,
  emailData,
  onCancel,
  onSend,
}) => {
  const [form] = Form.useForm();
  // const [emailString, setEmailString] = useState<string>("");
  const { pdfBuffer, qbLoading } = useContext(QuickbooksContext);

  useEffect(() => {
    if (emailData) {
      let emailString = emailData.to.join(",");
      form.setFieldsValue({ ...emailData, emailString });
    } else {
      form.setFieldsValue(EMAIL_PREVIEW_INITIAL_STATE);
    }
  }, [form, emailData]);

  const handleSend = () => {
    form
      .validateFields()
      .then((values) => {
        onSend({
          ...values,
          to: values.emailString.split(","),
          attachment: emailData.attachment,
        }); // Pass email data to the parent component
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  return (
    <Modal
      title="Email Preview"
      open={visible}
      centered
      onClose={onCancel}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} loading={qbLoading}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          onClick={handleSend}
          loading={qbLoading}
        >
          Send Email
        </Button>,
      ]}
      width={"100vw"} // Wider modal for side-by-side layout
    >
      <Row gutter={16}>
        {/* Email Composition Section */}
        <Col span={12}>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            initialValues={emailData}
          >
            <Form.Item
              label="To"
              name="emailString"
              rules={[
                () => ({
                  validator(_, value) {
                    const resultValidation = validateEmailsString(value);
                    if (resultValidation) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Invalid email format. Please separate multiple emails with commas (,)"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="Recipient email" />
            </Form.Item>
            <Form.Item
              label="CC"
              name="cc"
              rules={[{ type: "email", message: "Please enter a valid email" }]}
            >
              <Input placeholder="CC email (optional)" />
            </Form.Item>
            <Form.Item
              label="Reply To"
              name="replyTo"
              rules={[
                { required: true, message: "Recipient email is required" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Reply-to email (optional)" />
            </Form.Item>

            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Subject is required" }]}
            >
              <Input placeholder="Email subject" />
            </Form.Item>
            <Form.Item name="includePaymentLink" valuePropName="checked">
              <Checkbox>Include Payment Link</Checkbox>
            </Form.Item>
            <Form.Item
              label="Content"
              name="content"
              rules={[{ required: true, message: "Content is required" }]}
            >
              <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 10 }}
                placeholder="Write the content of the email"
              />
            </Form.Item>
          </Form>
        </Col>

        {/* PDF Preview Section */}
        <Col span={12}>
          <Divider orientation="left">Invoice Preview</Divider>
          <>{pdfBuffer ? <PdfViewer pdfBuffer={pdfBuffer} /> : <></>}</>
        </Col>
      </Row>
    </Modal>
  );
};
