import {
  Button,
  Grid,
  Row,
  Skeleton,
  Statistic,
  Tabs,
  TabsProps,
  Tour,
  Typography,
  Tooltip as TooltipAntd,
  StatisticProps,
  Spin,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CardTitle, ColumnComponent, SpinComponent } from "../../components/ui";
import QuickbooksContext from "../../context/QuickbooksContext";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { InvoiceProps } from "../../interfaces/interfaces";
import { InvoiceDrawer } from "../../components/collections/InvoiceDrawer";
import DataContext from "../../context/DataContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Base64 } from "js-base64";
import AuthContext from "../../context/AuthContext";
import { USDollar } from "../../utils/func/currency";
import dayjs from "dayjs";
import { TourProps } from "antd/lib";
import { useDemoModal } from "../../hooks";
import { colors } from "../../utils/data";
import utc from "dayjs/plugin/utc";
import CountUp from "react-countup";

dayjs.extend(utc);

const { Text } = Typography;
interface PieItemProps {
  Value: number;
  name: string;
  Id?: string;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const getPieColor = (item: PieItemProps, index: number) => {
  switch (item.name) {
    case "Current":
      return "#8884d8";
    case "1-30":
      return "#00C49F";
    case "31-60":
      return "#FFBB28";
    case "61-90":
      return "#FF8042";
    case ">90":
      return "#0088FE";
    default:
      return colors[index] || "#17549a";
  }
};

const CustomToolTip = (props: any) => {
  const { active, payload, label } = props;
  if (!active || !payload) {
    return null;
  }
  return (
    <div className="custom-tooltip">
      <p>
        <strong>{label}</strong>
      </p>
      {payload.map((item: any, i: number) => (
        <React.Fragment key={i}>
          {item.value !== 0 ? (
            <div
              key={i}
              style={{
                backgroundColor: item.fill || "white",
                padding: "10%",
                color: item.fill ? "white" : "black",
                borderRadius: "10px",
                border: "1px solid gray",
                boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                margin: "2px",
              }}
            >
              {item.name}: <strong>{USDollar.format(item.value)}</strong>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export const DashboardClient = () => {
  const { idClient } = useParams();
  const [searchParams] = useSearchParams();
  const { DemoModal, openModalDemo } = useDemoModal();
  const navigate = useNavigate();
  const {
    qbLoading,
    getData,
    dashboardData,
    isInvoiceDrawerOpen,
    invoiceListSelected,
    indexInvoiceSelected,
    invoiceSelected,
    dashboardStatusData,
    handleSelectInvoiceList,
    handleCancelSelectInvoiceList,
    handleSelectInvoice,
    updateSelectedInvoice,
    handleGetQbData,
  } = useContext(QuickbooksContext);
  const { onLogout, isDemoEnv, role } = useContext(AuthContext);
  const {
    getData: getStatus,
    statusList,
    isTourOpen,
    handleTour,
    handleWarning,
  } = useContext(DataContext);
  const [pieChartData, setPieChartData] = useState([{ Value: 0, name: "" }]);
  const [pieChartStatusData, setPieChartStatusData] = useState([
    { Value: 0, name: "" },
  ]);
  const [barChartData, setBarChartData] = useState([{ Value: 0, name: "" }]);
  const [barCharToptData, setBarChartTopData] = useState([
    { Value: 0, name: "" },
  ]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const refWarning = useRef(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    if (searchParams.get("message")) {
      const message = searchParams.get("message")?.toString();
      if (message) {
        const messageDecoded = Base64.decode(message);
        if (messageDecoded === "Not Authorized") {
          onLogout();
        } else {
          !refWarning.current && handleWarning(true, messageDecoded);
          refWarning.current = true;
          navigate("/dashboard");
        }
      }
    }
    if (searchParams.get("totals")) {
      const totals = searchParams.get("totals");
      if (totals) {
        sessionStorage.setItem("totals", totals);
        searchParams.delete("totals");
      }
    }
    if (searchParams.get("redirectTo")) {
      const redirectTo = searchParams.get("redirectTo");
      navigate(redirectTo || "/dashboard");
    }
  }, []);

  useEffect(() => {
    if (isDemoEnv) {
      let tourOpened = localStorage.getItem("tour");
      if (!tourOpened) {
        handleTour(true);
        localStorage.setItem("tour", "true");
      }
    }
  }, []);

  useEffect(() => {
    if (idClient) {
      getData({ endpoint: "dashboard", idClient });
      getStatus("status", idClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  useEffect(() => {
    if (dashboardData && dashboardData.invoicesByClient.length > 0) {
      const pieChartArray = [];
      dashboardData.current &&
        pieChartArray.push({
          name: "Current",
          Value: dashboardData.current,
        });
      dashboardData.total_1_30 &&
        pieChartArray.push({
          name: "1-30",
          Value: dashboardData.total_1_30,
        });
      dashboardData.total_31_60 &&
        pieChartArray.push({
          name: "31-60",
          Value: dashboardData.total_31_60,
        });
      dashboardData.total_61_90 &&
        pieChartArray.push({
          name: "61-90",
          Value: dashboardData.total_61_90,
        });
      dashboardData.total_90 &&
        pieChartArray.push({
          name: ">90",
          Value: dashboardData.total_90,
        });
      setPieChartData(pieChartArray);
      let topChartData: any[] = [];
      dashboardData.top90.forEach((top90Invoice) => {
        top90Invoice[">90"] &&
          topChartData.push({
            ">90": getTotalValue(top90Invoice[">90"]),
            Client: top90Invoice._id.name.slice(0, 15),
            invoices: top90Invoice[">90"],
          });
      });
      topChartData.length > 0 && setBarChartTopData(topChartData);

      let chartData: any[] = [];
      dashboardData.invoicesByClient.forEach((invoicesByClient) => {
        chartData.push({
          name: invoicesByClient._id.name.slice(0, 15),
          Current:
            invoicesByClient.current.length > 0
              ? getTotalValue(invoicesByClient.current)
              : 0,
          "1-30":
            invoicesByClient["1-30"].length > 0
              ? getTotalValue(invoicesByClient["1-30"])
              : 0,
          "31-60":
            invoicesByClient["31-60"].length > 0
              ? getTotalValue(invoicesByClient["31-60"])
              : 0,
          "61-90":
            invoicesByClient["61-90"].length > 0
              ? getTotalValue(invoicesByClient["61-90"])
              : 0,
          ">90":
            invoicesByClient[">90"].length > 0
              ? getTotalValue(invoicesByClient[">90"])
              : 0,
          invoiceList: invoicesByClient && invoicesByClient,
        });
      });
      chartData.length > 0 && setBarChartData(chartData);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardStatusData && dashboardStatusData.length > 0) {
      let pieChartArray: PieItemProps[] = [];
      for (let i = 0; i < dashboardStatusData.length; i++) {
        pieChartArray.push({
          name: dashboardStatusData[i].status_field[0].Label,
          Value: dashboardStatusData[i].total,
          Id: dashboardStatusData[i].status_field[0]._id,
        });
        setPieChartStatusData(pieChartArray);
      }
    }
  }, [dashboardStatusData]);

  const getTotalValue = (invoices: InvoiceProps[]) => {
    return invoices.reduce((acc, invoice) => {
      return acc + invoice.Balance;
    }, 0);
  };
  const getLastUpdate = () => {
    if (dashboardData.lastUpdate && dashboardData.lastUpdate.CollectionsDate)
      return (
        <div>
          <Text style={{ color: "#FFF" }}>
            Last update on:{" "}
            {dayjs
              .utc(dashboardData.lastUpdate.CollectionsDate)
              .format("MM/DD/YYYY HH:mm")}
          </Text>
        </div>
      );
  };

  const steps: TourProps["steps"] = [
    {
      title: "Refresh From QuickBooks",
      description:
        " Click to pull your updated data from QuickBooks. This will refresh",
      target: () => ref5.current,
    },
    {
      title: "Total Aging",
      description:
        "This represents the total amount of outstanding receivables for your account",
      target: () => ref1.current,
    },
    {
      title: "Current A/R",
      description:
        "Current Accounts Receivable indicates the total money owed to a business by its customers. This metric helps businesses forecast incoming revenue and manage cash flow more effectively.",
      target: () => ref2.current,
    },
    {
      title: "> 90 A/R",
      description:
        "Represents the total amount of accounts receivable transactions that are more than 90 days past due.",
      target: () => ref3.current,
    },
    {
      title: "Charts",
      description: (
        <>
          <>
            These charts provide a visual breakdown of your accounts receivable
            (A/R)
          </>
        </>
      ),
      target: () => ref4.current,
    },
  ];

  const renderColorfulLegendText = (value: string, entry: any) => {
    return (
      <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
        {value}
      </span>
    );
  };

  const RenderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul style={{ listStyle: "none" }}>
        {payload.map((entry: any, index: number) => (
          <li key={index}>
            <span
              style={{
                backgroundColor: colors[index],
                borderRadius: "50%",
                width: "10px",
                height: "10px",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            <span key={`item-${index}`}>{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Total A/R",
      children: (
        <PieChart width={xs ? 200 : 500} height={xs ? 200 : 400}>
          <Pie
            data={pieChartData}
            dataKey="Value"
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
            legendType="circle"
            paddingAngle={1}
            minAngle={1}
            outerRadius={xs ? 70 : 150}
            fill="#8884d8"
            onClick={(data) => {
              if (data.payload.payload.name) {
                navigate(
                  `/detail/${idClient}?filter=${data.payload.payload.name}`
                );
              }
            }}
          >
            {pieChartData.map((value, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getPieColor(value as PieItemProps, index)}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomToolTip />} />
          <Legend />
        </PieChart>
      ),
    },
    {
      key: "2",
      label: "Top 5 A/R",
      children: (
        <BarChart
          width={xs ? 300 : 600}
          height={xs ? 200 : 400}
          data={barChartData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 10, width: 500 }} />
          <YAxis tick={{ fontSize: 12, width: 500 }} />
          <Tooltip content={<CustomToolTip />} />
          <Legend />
          <Bar
            dataKey="Current"
            stackId="a"
            fill="#8884d8"
            onClick={(data) =>
              handleSelectInvoiceList("client", data.payload.invoiceList)
            }
          />
          <Bar
            dataKey="1-30"
            stackId="a"
            fill="#00C49F"
            onClick={(data) =>
              handleSelectInvoiceList("client", data.payload.invoiceList)
            }
          />
          <Bar
            dataKey="31-60"
            stackId="a"
            fill="#FFBB28"
            onClick={(data) =>
              handleSelectInvoiceList("client", data.payload.invoiceList)
            }
          />
          <Bar
            dataKey="61-90"
            stackId="a"
            fill="#FF8042"
            onClick={(data) =>
              handleSelectInvoiceList("client", data.payload.invoiceList)
            }
          />
          <Bar
            dataKey=">90"
            stackId="a"
            fill="#0088FE"
            onClick={(data) =>
              handleSelectInvoiceList("client", data.payload.invoiceList)
            }
          />
        </BarChart>
      ),
    },
    {
      key: "3",
      label: "Top 5 >90",
      children: (
        <BarChart
          width={xs ? 300 : 600}
          height={xs ? 200 : 400}
          data={barCharToptData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Client" tick={{ fontSize: 10, width: 500 }} />
          <YAxis tick={{ fontSize: 12, width: 500 }} />
          <Tooltip content={<CustomToolTip />} />
          <Legend />
          <Bar
            dataKey=">90"
            stackId="a"
            fill="#0088FE"
            onClick={(data) => handleSelectInvoiceList("client", data.payload)}
          />
        </BarChart>
      ),
    },
    {
      key: "4",
      label: "A/R By Status",
      children: (
        <PieChart width={xs ? 200 : 600} height={xs ? 200 : 400}>
          <Legend
            layout="vertical"
            iconSize={10}
            iconType="circle"
            formatter={renderColorfulLegendText}
            align="right"
            verticalAlign="middle"
            content={<RenderLegend />}
          />
          <Pie
            data={pieChartStatusData}
            dataKey="Value"
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            labelLine={false}
            legendType="circle"
            paddingAngle={1}
            minAngle={1}
            outerRadius={xs ? 70 : 150}
            fill="#8884d8"
            onClick={(data) => {
              if (data.payload.payload.Id) {
                navigate(
                  `/detail/${idClient}?status=${data.payload.payload.Id}`
                );
              }
            }}
          >
            {pieChartStatusData.map((value, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getPieColor(value as PieItemProps, index)}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomToolTip />} />
        </PieChart>
      ),
    },
  ];

  return (
    <div className="dashboard-client">
      <Row gutter={[24, 0]} className="dashboard-client">
        <ColumnComponent>
          <CardTitle
            title="Dashboard"
            className="dashboard-client__title"
            extra={
              isDemoEnv
                ? []
                : [
                    getLastUpdate(),
                    <div
                      style={{
                        textAlign: "center",
                        padding: "5px",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "#2CA01C",
                          color: "#FFF",
                          borderColor: "#2CA01C",
                        }}
                        loading={qbLoading}
                        size="small"
                        onClick={() =>
                          idClient &&
                          handleGetQbData(idClient, () =>
                            getData({ endpoint: "dashboard", idClient })
                          )
                        }
                      >
                        Refresh From QuickBooks
                      </Button>
                    </div>,
                  ]
            }
          />
        </ColumnComponent>
        <ColumnComponent lg={8} xl={8} className="dashboard-client__total-ar">
          <ColumnComponent>
            <div ref={ref1} className="dashboard-client__total-ar">
              <Statistic
                title="Total A/R"
                className="dashboard-client__total-ar"
                value={USDollar.format(
                  dashboardData.total ? dashboardData.total : 0
                )}
              />
            </div>
          </ColumnComponent>
          <ColumnComponent>
            <div ref={ref2} className="dashboard-client__total-ar">
              <Statistic
                title="Current A/R"
                className="dashboard-client__total-ar"
                value={USDollar.format(
                  dashboardData.current ? dashboardData.current : 0
                )}
              />
            </div>
          </ColumnComponent>
          <ColumnComponent>
            <div ref={ref3} className="dashboard-client__total-ar">
              <Statistic
                title="> 90 A/R"
                className="dashboard-client__total-ar"
                value={USDollar.format(
                  dashboardData.total_90 ? dashboardData.total_90 : 0
                )}
              />
            </div>
          </ColumnComponent>
        </ColumnComponent>
        <ColumnComponent lg={16} xl={16} className="dashboard-client__tabs">
          <div ref={ref4} className="dashboard-client__tabs">
            <Tabs
              tabPosition={"top"}
              className="dashboard-client__tabs"
              type="card"
              key={"key"}
              items={items}
              onTabClick={(key) => {
                if (key === "4") {
                  idClient &&
                    getData({
                      endpoint: "dashboard-status",
                      idClient,
                      allowLoading: false,
                    });
                }
              }}
            />
          </div>
        </ColumnComponent>
      </Row>
      <InvoiceDrawer
        title={`Collections (${invoiceSelected.TransactionType} ${
          indexInvoiceSelected + 1
        } of ${invoiceListSelected.length})`}
        list={invoiceListSelected}
        idClient={idClient}
        indexInvoiceSelected={indexInvoiceSelected}
        open={isInvoiceDrawerOpen}
        onClose={handleCancelSelectInvoiceList}
        invoice={invoiceSelected}
        options={statusList}
        selectInvoice={(indexSelected) => handleSelectInvoice(indexSelected)}
        onClickNext={
          indexInvoiceSelected < invoiceListSelected.length - 1
            ? () => handleSelectInvoice(indexInvoiceSelected + 1)
            : undefined
        }
        onClickPrevious={
          indexInvoiceSelected !== undefined && indexInvoiceSelected > 0
            ? () => handleSelectInvoice(indexInvoiceSelected - 1)
            : undefined
        }
        onSubmit={(newNote, emailAddress, phoneNumber, newDate) => {
          if (isDemoEnv) {
            openModalDemo();
          } else {
            idClient &&
              updateSelectedInvoice(
                idClient,
                {
                  newNote,
                  emailAddress,
                  phoneNumber,
                  newDate,
                },
                () => {}
              );
          }
        }}
      />
      <Tour open={isTourOpen} onClose={() => handleTour(false)} steps={steps} />
      <DemoModal />
    </div>
  );
};
