import React from "react";

interface PdfViewerProps {
  pdfBuffer: string | null;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfBuffer }) => {
  return (
    <div>
      {pdfBuffer && (
        <iframe src={pdfBuffer} width="100%" height="550px" title="PDF Viewer">
          This browser does not support PDFs. Please download the PDF to view
          it: <a href={pdfBuffer}>Download PDF</a>
        </iframe>
      )}
    </div>
  );
};

export default PdfViewer;
