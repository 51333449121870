import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from "antd";
import QuickbooksContext from "../../context/QuickbooksContext";
import { USDollar } from "../../utils/func/currency";
import TextArea from "antd/es/input/TextArea";
import { useDemoModal } from "../../hooks";
import AuthContext from "../../context/AuthContext";
import { ColumnComponent } from "../ui";
import DataContext from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { validateEmailsString } from "../../utils/func/utils";

export const CustomerInfo = ({
  idClient,
  isClosing,
}: {
  idClient?: string;
  isClosing?: boolean;
}) => {
  const navigate = useNavigate();
  const { customerInfo, updateQuickbooksData, qbLoading, getCustomerData } =
    useContext(QuickbooksContext);
  const { getData, termOptions, handleEditData } = useContext(DataContext);
  const { isDemoEnv, role } = useContext(AuthContext);
  const { DemoModal, openModalDemo } = useDemoModal();
  const [hasChanged, setHasChanged] = useState({ qbNotes: false, term: false });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [newData, setNewData] = useState({
    qbNotes: "",
    term: { name: "", value: "" },
    phoneNumber: "",
    emailAddress: "",
  });
  const [isBasicUser, setIsBasicUser] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    idClient && getData("term", idClient);
  }, [idClient]);

  useEffect(() => {
    if (customerInfo) {
      setNewData((prev) => ({
        ...prev,
        qbNotes: customerInfo.Notes || "",
        term: {
          value: customerInfo.SalesTermRef?.value || "",
          name: customerInfo.SalesTermRef?.name || "",
        },
        phoneNumber: customerInfo.CollectionsProfile?.PhoneNumber || "",
        emailAddress: customerInfo.CollectionsProfile?.EmailAddress || "",
      }));
    }
  }, [customerInfo]);

  useEffect(() => {
    if (role !== "READ" && role !== "DEMO") setIsBasicUser(false);
  }, [role]);

  useEffect(() => {
    if (isClosing) {
      setHasChanged({ qbNotes: false, term: false });
    }
  }, [isClosing]);

  useEffect(() => {
    if (newData.emailAddress) {
      setIsValidEmail(validateEmailsString(newData.emailAddress));
    } else {
      if (newData.emailAddress === "") {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  }, [newData.emailAddress]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: <strong>Company Name</strong>,
      children: (
        <>{customerInfo?.CompanyName || customerInfo?.DisplayName || ""}</>
      ),
      className: "descriptions__label",
    },
    {
      key: "2",
      label: <strong>Total Balance</strong>,
      children: <>{USDollar.format(customerInfo?.Balance) || ""}</>,
      className: "descriptions__label",
    },
    {
      key: "7",
      label: <strong>Billing Address</strong>,
      children: (
        <>
          {customerInfo?.BillAddr?.Line1} {customerInfo?.BillAddr?.City}
        </>
      ),
      className: "descriptions__label",
    },
    {
      key: "3",
      label: <strong>Last Payment Amount</strong>,
      children: (
        <>
          {customerInfo &&
          customerInfo.lastPayment &&
          customerInfo.lastPayment[0]
            ? USDollar.format(customerInfo?.lastPayment[0].TotalAmt)
            : "No Payment"}
        </>
      ),
      className: "descriptions__label",
    },
    {
      key: "9",
      label: <strong>Mobile</strong>,
      children: <>{customerInfo?.Mobile?.FreeFormNumber || ""}</>,
      className: "descriptions__label",
    },

    {
      key: "4",
      label: <strong>Last Payment Date</strong>,
      children: (
        <>
          {customerInfo &&
          customerInfo.lastPayment &&
          customerInfo.lastPayment[0]
            ? new Date(customerInfo?.lastPayment[0].TxnDate)
                .toISOString()
                .split("T")[0]
            : "No Info"}
        </>
      ),
      className: "descriptions__label",
    },

    {
      key: "8",
      label: <strong>Phone Number</strong>,
      children: <>{customerInfo?.PrimaryPhone?.FreeFormNumber || ""}</>,
      className: "descriptions__label",
    },
    {
      key: "6",
      label: <strong>Customer Type</strong>,
      children: <>{customerInfo?.CustomerType?.Name || ""}</>,
      className: "descriptions__label",
    },

    {
      key: "10",
      label: <strong>Email Address</strong>,
      children: <>{customerInfo?.PrimaryEmailAddr?.Address || ""}</>,
      className: "descriptions__label",
    },
    {
      key: "11",
      label: <strong>Details</strong>,
      children: (
        <Button
          type="link"
          size="small"
          onClick={() =>
            navigate(`/detail/${idClient}/?add_filter=${customerInfo?.Id}`)
          }
        >
          See All Invoices
        </Button>
      ),
      className: "descriptions__label",
    },
    {
      key: "12",
      label: <strong>Contact Information </strong>,
      span: 2,
      children: (
        <>
          <Form layout="vertical" initialValues={newData} form={form}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Email Address"
                  className="collections-form__form-item"
                >
                  <Input
                    name="Contact Information"
                    placeholder="Email Address"
                    multiple
                    value={newData.emailAddress}
                    suffix={
                      <Tooltip title="Use commas to separate multiple email addresses">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                    onChange={(event) => {
                      setNewData((prev) => ({
                        ...prev,
                        emailAddress: event.target.value,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Phone Number"
                  className="collections-form__form-item"
                >
                  <Input
                    name="Phone Number"
                    placeholder="Phone Number"
                    multiple
                    value={newData.phoneNumber}
                    onChange={(event) => {
                      setNewData((prev) => ({
                        ...prev,
                        phoneNumber: event.target.value,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Form.Item
                className="collections-form__form-item__button"
                style={{
                  textAlign: "end",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={qbLoading}
                  disabled={!isValidEmail}
                  onClick={() => {
                    handleEditData({
                      endpoint: `customer/${idClient}/${customerInfo._id}`,
                      newData: {
                        newValues: {
                          "CollectionsProfile.EmailAddress":
                            newData.emailAddress,
                          "CollectionsProfile.PhoneNumber": newData.phoneNumber,
                        },
                      },
                      next: () => {
                        idClient &&
                          getCustomerData(customerInfo.Id, idClient, () => {});
                      },
                    });
                  }}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </>
      ),
      className: "descriptions__label",
    },
  ];

  return (
    <>
      <Row>
        <ColumnComponent>
          <Descriptions
            layout="horizontal"
            size="small"
            bordered
            column={2}
            items={items}
            className="descriptions"
          />
        </ColumnComponent>
        <ColumnComponent>
          <Form
            layout="vertical"
            form={form}
            onFinish={() => {
              if (isDemoEnv) {
                openModalDemo();
              } else {
                let dataToUpdate = {};
                if (hasChanged.qbNotes) {
                  dataToUpdate = { ...dataToUpdate, Notes: newData.qbNotes };
                }
                if (hasChanged.term) {
                  dataToUpdate = {
                    ...dataToUpdate,
                    SalesTermRef: {
                      value: newData.term.value,
                      name: newData.term.name,
                    },
                  };
                }
                if (
                  dataToUpdate.hasOwnProperty("SalesTermRef") ||
                  dataToUpdate.hasOwnProperty("Notes")
                ) {
                  updateQuickbooksData({
                    endpoint: `customer-quickbooks/${idClient}/${customerInfo.Id}`,
                    updates: { newData: dataToUpdate },
                    next: () => setHasChanged({ qbNotes: false, term: false }),
                  });
                }
              }
            }}
          >
            <Form.Item label="Terms" className="collections-form__form-item">
              <Select
                showSearch
                placeholder="Quickbooks Customer Terms"
                optionFilterProp="children"
                filterOption={filterOption}
                value={newData.term.value}
                onChange={(_, item: any) => {
                  setHasChanged((prev) => ({ ...prev, term: true }));
                  setNewData((prev) => ({
                    ...prev,
                    term: {
                      value: item.value,
                      name: item.label,
                    },
                  }));
                }}
                disabled={isBasicUser}
                options={termOptions}
              />
            </Form.Item>
            <Form.Item label="Notes">
              <TextArea
                name="qbNotes"
                placeholder="QuickBooks Notes"
                disabled={isBasicUser}
                value={newData.qbNotes}
                onChange={(event) => {
                  setHasChanged((prev) => ({ ...prev, qbNotes: true }));
                  setNewData((prev) => ({
                    ...prev,
                    qbNotes: event.target.value,
                  }));
                }}
              />
            </Form.Item>
            {!isBasicUser && (
              <Form.Item
                style={{
                  textAlign: "center",
                }}
              >
                <Button
                  disabled={!(hasChanged.qbNotes || hasChanged.term)}
                  className="collections-form-quickbooks__button"
                  loading={qbLoading}
                  htmlType="submit"
                >
                  Update QuickBooks Customer Information
                </Button>
              </Form.Item>
            )}
          </Form>
        </ColumnComponent>
      </Row>
      <DemoModal />
    </>
  );
};
